<template>
  <ZNavItem
    v-if="isLoggedIn"
    :to="localePath('dashboard-rvs-new')"
    data-testid="list-an-rv"
    exact
    link-classes="zbtn zbtn-primary zbtn-lg text-light"
  >
    {{ $t('pageLink.listAnRV') }}
  </ZNavItem>
  <template v-else>
    <ZNavText class="d-none d-lg-block">
      {{ t('cta') }}
    </ZNavText>
    <ZNavItem
      role="link"
      data-testid="sign-up"
      class="ml-2"
      link-classes="zbtn zbtn-primary zbtn-lg text-light"
      @click="showSignup({ intent: AUTHENTICATION_INTENT.ListingIntent })"
    >
      {{ t('button') }}
    </ZNavItem>
  </template>
</template>

<script setup lang="ts">
import { AUTHENTICATION_INTENT } from '~/constants/authentication'

const { t } = useI18n({
  useScope: 'local',
})
const localePath = useLocalePath()

const { isLoggedIn } = useAuthentication()
const { showSignup } = useSignup()
</script>

<i18n lang="json">
{
  "en": {
    "cta": "Join RVezy. It's free!",
    "button": "Get Started"
  },
  "fr": {
    "cta": "Rejoignez RVezy, c'est gratuit!",
    "button": "Commencer"
  }
}
</i18n>
